import { application } from 'services/core'

function shopFormSelection () {
  function mainCategorySelection () {
    const elements = {}
    const initializeSelectors = () => {
      elements.mainCategory = document.querySelector('#main_category')
      elements.shopCategories = document.querySelector('#shop_categories')

      elements.attachedImages =
        document.querySelector('.attached_photos').getAttribute('data-photos')
      elements.uploadButtons = document.querySelectorAll('.upload-btn')
    }
    const setCurrentMainCategory = () => {
      if (elements.mainCategory) {
        localStorage.setItem('mainCategoryValue', elements.mainCategory.value)
      }
    }

    const initializeListeners = () => {
      const submitBtn = document.querySelector('.form_submit')

      displayPreviewInit()

      if (elements.mainCategory) {
        elements.mainCategory.addEventListener('change', ShopService, false)
      }
      if (submitBtn) {
        submitBtn.addEventListener('click', CheckMainCategoryIsInsideTypes, false)
      }
      if (elements.uploadButtons) {
        elements.uploadButtons.forEach((btn) => {
          btn.addEventListener('change', displayPreview, false)
        })
      }
    }

    const CheckMainCategoryIsInsideTypes = (event) => {
      event.preventDefault()

      const inclusionOfMainInType = Array.from(elements.shopCategories).filter((item) => {
        return (item.selected && item.value === elements.mainCategory.value)
      })

      if (inclusionOfMainInType.length === 0) {
        alert('The list of Types should contain the Main Category')

        return false
      } else {
        event.target.parentElement.parentElement.parentElement.parentElement.submit()
      }
    }

    const ShopService = (element) => {
      const mainCategory = elements.mainCategory.options[elements.mainCategory.selectedIndex]
      const isCategoryActive = mainCategory.getAttribute('data-is-active')
      const oldMainCategoryInTypes = localStorage.getItem('mainCategoryValue')

      if (isCategoryActive === 'false') {
        element.currentTarget.style.backgroundColor = '#F7CECF'
      } else {
        element.currentTarget.style.backgroundColor = '#fff'
      }

      if (oldMainCategoryInTypes === null || oldMainCategoryInTypes !== mainCategory.value) {
        $('#shop_categories').multiselect('deselect', oldMainCategoryInTypes)
        localStorage.setItem('mainCategoryValue', mainCategory.value)
      }

      const newMainCategoryInTypes = Array.from(elements.shopCategories.options).find((item) => {
        return item.value === mainCategory.value
      })

      $('#shop_categories').multiselect('select', newMainCategoryInTypes.value)
    }

    const displayPreview = (event) => {
      const id = event.target.id

      const input = document.getElementById(id)
      const previewElement = document.getElementById(`laas-store-instructions-form-preview-${id}`)

      const reader = new FileReader()
      reader.onload = function (event) {
        previewElement.src = event.target.result
        previewElement.classList.add('shop-ppd-details__display')
        previewElement.classList.remove('shop-ppd-details__hide')
      }

      reader.readAsDataURL(input.files[0])
    }

    const displayPreviewInit = () => {
      if (elements.attachedImages) {
        JSON.parse(elements.attachedImages).forEach(function (link, index) {
          const previewElement =
            document.getElementById(`laas-store-instructions-form-preview-${index + 1}`)
          previewElement.src = link.path
          previewElement.classList.add('shop-ppd-details__display')
          previewElement.classList.remove('shop-ppd-details__hide')
        })
      }
    }

    return () => {
      initializeSelectors()
      setCurrentMainCategory()
      initializeListeners()
    }
  }

  function associatedShopCategory () {
    $('#service_type').on('change', function () {
      const categoryDropdown = $('#main_category')
      const serviceTypeId = $(this).val()

      $.ajax({
        url: `/service_types/${serviceTypeId}/associated_shop_categories`,
        type: 'GET',
        dataType: 'json',
        success (data) {
          categoryDropdown.html(optionsForDropdown(data.shop_categories))
        }
      })
    })
  }

  function optionsForDropdown (categories) {
    let categoriesOptions = "<option value=''>Please Select</option>"

    categories.forEach(function (category) {
      categoriesOptions += `<option
                              data-is-active=${category.is_active}
                              value="${category.id}">
                              ${category.name}
                            </option>`
    })
    return categoriesOptions
  }

  function serviceTypeSelection () {
    const elements = {}
    const initializeSelectors = () => {
      elements.serviceType = document.querySelector('#service_type')
      elements.shopCategories = document.querySelector('#main_category')
    }
    const setCurrentServiceType = () => {
      localStorage.setItem('serviceTypeValue', elements.serviceType.value)
    }

    const initializeListeners = () => {
      const submitBtn = document.querySelector('.form_submit')

      elements.serviceType.addEventListener('change', ShopService, false)
      submitBtn.addEventListener('click', CheckServiceTypeIsInsideTypes, false)
    }

    const CheckServiceTypeIsInsideTypes = (event) => {
      event.preventDefault()

      const inclusionOfMainInType = Array.from(elements.shopCategories).filter((item) => {
        return (item.selected && item.value === elements.serviceType.value)
      })

      if (inclusionOfMainInType.length === 0) {
        alert('The list of Types should contain the service type')

        return false
      } else {
        event.target.parentElement.parentElement.parentElement.parentElement.submit()
      }
    }

    const ShopService = (element) => {
      const serviceType = elements.serviceType.options[elements.serviceType.selectedIndex]
      const isCategoryActive = serviceType.getAttribute('data-is-active')
      const oldServiceTypeInTypes = localStorage.getItem('serviceTypeValue')

      if (isCategoryActive === 'false') {
        element.currentTarget.style.backgroundColor = '#F7CECF'
      } else {
        element.currentTarget.style.backgroundColor = '#fff'
      }

      if (oldServiceTypeInTypes === null || oldServiceTypeInTypes !== serviceType.value) {
        $('#main_category').multiselect('deselect', oldServiceTypeInTypes)
        localStorage.setItem('serviceTypeValue', serviceType.value)
      }

      const newServiceTypeInTypes = Array.from(elements.shopCategories.options).find((item) => {
        return item.value === serviceType.value
      })

      $('#main_category').multiselect('select', newServiceTypeInTypes.value)
    }

    return () => {
      initializeSelectors()
      setCurrentServiceType()
      initializeListeners()
    }
  }

  if (window.location.pathname.includes('shop_services')) {
    serviceTypeSelection()
    associatedShopCategory()
    mainCategorySelection()()
  }
}

export default shopFormSelection
